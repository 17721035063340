<template>
  <div class="backbar is-flex justify-flex-start align-items-center"
    :class="{
      'clean': isClean
    }"
  >
    <InlineSvg
      :src="isClean ? require('@/assets/icon/chevron-left-black.svg') : require('@/assets/icon/chevron-left-white.svg')"
      width="24"
      height="24"
      @click="$emit('onBackClick')"/>
    <div class="has-margin-left-10 _fs-24 _fw-500 _lh-1-4x">{{title}}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    isClean: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.backbar {
  height: 80px;
  background: $home-header;
  padding: 24px 20px;
  color: white;
  &.clean {
    background: transparent;
    color: black;
  }
}
</style>
