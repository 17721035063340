<template>
  <div>
    <button class="btn" :class="[
      type,
      {
        'full': isFull
      }
    ]" @click="handleClick"
      :disabled="disabled"
    >
      <span><slot>Button</slot></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    onClick: {
      type: Function
    },
    isFull: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.btn {
  outline: none;
  touch-action: manipulation;
  border-radius: 28px;
  height: 42px;
  min-width: 205px;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 1.4rem;
  font-weight: 500;
  border: solid 1px $secondary;
  color: white;
  background-color: $secondary;
  cursor: pointer;
  &.error {
    color: $error;
    background-color: white;
    border: 1px solid $error;
  }
  &.success {
    color: white;
    background-color: $secondary;
    border: none;
  }
  &.outline {
    color: $btn-outline;
    background-color: transparent;
    padding: 0;
    text-align: center;
    border: 1px solid $border;
  }
  &.full {
    width: 100%;
  }
  &.revert {
    color: $secondary;
    background: white;
    border: 1px solid white;
  }
  &[disabled=disabled], &:disabled {
    color: white;
    background: $grey9a;
    border: solid 1px $grey9a;
  }
  &.white {
    color: $secondary;
    background-color: white;
    border: none;
  }
  &.text {
    color: rgba($color: #000000, $alpha: 0.4);
    background-color: transparent;
    border: none;
  }
  &.danger {
    color: $danger;
    background-color: white;
    border: none;
  }
}
</style>
