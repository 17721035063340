<template>
  <div class="base-select">
    <select
      :class="{
        'empty': (fieldValue === null)
      }"
      v-model="fieldValue"
      v-bind="$attrs"
      :name="name"
      :id="idName"
      :required="required"
      @change="onChangeCallback">
      <template v-if="placeholder">
        <option
          class="placeholder"
          v-if="fieldValue == null"
          :value="null"
          disabled
          hidden
          >
          {{ placeholder }}
        </option>
      </template>
      <slot/>
    </select>
    <div class="icon-wrapper">
      <img class="icon" src="@/assets/icon/chevron-down.svg" width="16" height="16"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: {
      type: [String, Number, Boolean, Object, Array, Symbol, Function],
      default: null
    },
    name: String,
    idName: String,
    className: String,
    values: Array,
    options: Array,
    onChange: { type: Function, default: null },
    required: { type: Boolean, default: false }
  },
  data () {
    return {
      fieldValue: this.value
    }
  },
  // computed: {
  //   computedValue: {
  //     get () {
  //       return this.selected
  //     },
  //     set (value) {
  //       this.selected = value
  //       this.$emit('input', value)
  //       // !this.isValid && this.checkHtml5Validity()
  //     }
  //   }
  // },
  watch: {
    value: function (newVal, oldVal) {
      this.fieldValue = this.value
    }
  },
  methods: {
    onChangeCallback () {
      if (this.onChange != null) {
        this.onChange(this.fieldValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.base-select {
  position: relative;
  width: 100%;
  .placeholder {
    color: $placeholder
  }
  select{
    font-weight: 400;
    width: 100%;
    min-height: 100%;
    height: 24px;
    border-radius: 8px;
    padding: 2px 8px;
    border: 1px solid $grey97;
    font-size: 1.2rem;
    background: white;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    &.has-suffix {
      padding-right: calc(46px + 12px);
    }
    &.error {
      border: 1.5px solid $error;
    }
    &:focus {
      outline: none;
      border: 1.0px solid $secondary;
    }
    &::placeholder {
      color: $placeholder;
    }
    &:disabled {
      background: $bg-disable-placeholder;
      color: $disable-placeholder;
    }
    &.grey {
      background: $light-gray;
      color: $black;
      border: none;
      font-weight: 400;
      font-size: 14px;
      padding: 9px 18px 10px 18px;
      &:focus {
        outline: none;
        border: none;
      }
      &::placeholder {
        color: $black;
      }
    }
  }
  option {
    color: $primary;
    text-align: center;
    text-align-last: center;
    background: transparent;
  }
  option:disabled {
    cursor: not-allowed;
    color: $placeholder;
  }
  .arrow {
    width: 15px;
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .icon-wrapper {
    width: 20px;
    height: 24px;
    // background: $primary;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 2px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .icon {
    // width: 24px;
    // height: 24px;
  }
}
</style>
