<template>
  <div class="base-select">
    <select
      :class="{
        'empty': (computedValue === null)
      }"
      v-model="computedValue"
      v-bind="$attrs"
      @change="onChange">
      <template v-if="placeholder">
        <option
          v-if="computedValue == null"
          :value="null"
          disabled
          hidden
          >
          {{ placeholder }}
        </option>
      </template>
      <slot/>
    </select>
    <div class="icon-wrapper">
      <img class="icon" src="@/assets/icon/chevron-down.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: {
      type: [String, Number, Boolean, Object, Array, Symbol, Function],
      default: null
    }
  },
  data () {
    return {
      selected: this.value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.selected
      },
      set (value) {
        this.selected = value
        this.$emit('input', value)
        // !this.isValid && this.checkHtml5Validity()
      }
    }
  },
  watch: {
    value (value) {
      this.selected = value
    }
  },
  methods: {
    onChange () {
      this.$emit('change', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.base-select {
  position: relative;
  width: 100%;
  select{
    line-height: 1.8;
    font-weight: 500;
    font-size: 1.6rem;
    min-height: 100%;
    width: 100%;
    border: none;
    padding: 0px 0px 20px 0px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: black;
    &:focus {
      outline: none;
      // border: 1.5px solid white;
    }
    &::-ms-expand {
      display: none;
    }
    color: black;
    &.empty {
      color: $black40
    }
  }
  option {
    background: white;
    color: $primary
  }
  option:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: $black40
  }
  .arrow {
    width: 15px;
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .icon-wrapper {
    width: 42px;
    height: 42px;
    // background: $primary;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 8px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}
</style>
